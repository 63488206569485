<template>
  <div class="inner-container" :class="{ 'min-container': !getTabs }">
    <div class="page-header">
      <h1>{{ $t("LANGUAGES") }}</h1>
      <b-button
        size="sm"
        variant="success"
        class="button-ajout float-right"
        @click="handleStore"
        ><font-awesome-icon icon="plus-circle" /> {{ $t("NEW") }}</b-button
      >
    </div>

    <storeModalVue @updateList="setup(true)"></storeModalVue>

    <div class="content">
      <div class="filtre">
        <p class="table-count">{{ getTotalLangue }} {{ $t("LANGUAGES") }}</p>
      </div>
      <div class="table-rapport-style w-100">
        <table>
          <thead style="border-radius: 5px 0px 0px 0px">
            <tr>
              <th>
                {{ $t("WORDING") }}
              </th>
              <th style="border-radius: 0px 5px 0px 0px">iso_code</th>
              <!-- <th class="action_head"></th> -->
            </tr>
          </thead>
          <tbody>
            <tr
              role="row"
              class="ligneNormale"
              v-for="(data, i) in getActiveLangue"
              :key="i"
              :class="{ no_default: data.is_default }"
            >
              <td aria-colindex="1" role="cell">
                {{ data.lib }}
              </td>
              <td aria-colindex="1" role="cell">
                {{ data.iso_code }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <b-modal
        id="ModelConfiglangue"
        ref="ModelConfiglangue"
        @hide="v$.$reset()"
        title="Configuration"
        hide-footer
      >
        <template #modal-header="{ close }">
          <h5>{{ $t("EDIT") }} {{ $t("LANGUAGES") }}</h5>
          <b-button size="sm" @click="close()">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17.028"
              height="17.028"
              viewBox="0 0 17.028 17.028"
            >
              <path
                id="Icon_material-close"
                data-name="Icon material-close"
                d="M24.528,9.215,22.813,7.5l-6.8,6.8-6.8-6.8L7.5,9.215l6.8,6.8-6.8,6.8,1.715,1.715,6.8-6.8,6.8,6.8,1.715-1.715-6.8-6.8Z"
                transform="translate(-7.5 -7.5)"
                fill="#393939"
              />
            </svg>
          </b-button>
        </template>

        <form @submit.prevent="langueModalNew" v-if="langueToConfig">
          <div class="center">
            <b-form-group :label="$t('WORDING')" label-for="name">
              <b-form-input
                id="name"
                v-model="langueToConfig.lib"
              ></b-form-input>
              <div v-if="v$.langueToConfig.lib.$error" class="error-message">
                {{ $t("REQUIRED_FIELD") }}
              </div>
            </b-form-group>
            <b-form-group label="iso_code" label-for="iso_code">
              <b-form-input
                id="iso_code"
                v-model="langueToConfig.iso_code"
              ></b-form-input>
              <div
                v-if="v$.langueToConfig.iso_code.$error"
                class="error-message"
              >
                {{ $t("REQUIRED_FIELD") }}
              </div>
            </b-form-group>
          </div>

          <div class="actionModel">
            <b-alert variant="warning" show v-if="errorU">
              {{ errorU }}
            </b-alert>
            <b-button
              class="mr-2 succes-btn-modal btn-success float-right"
              type="submit"
            >
              <div class="block-spinner">
                {{ $t("EDIT") }}
                <div v-if="getLoadinglangue" class="loading ml-2">
                  <div class="spinner-border" role="status"></div>
                </div>
              </div>
            </b-button>
          </div>
        </form>
      </b-modal>

      <deleteModalVue
        :loader="getLoadinglangue"
        :elemToDelete="langueToDelete"
        :elemDelete="delete_langue"
        @updateList="setup(false)"
      ></deleteModalVue>

      <b-pagination
        v-model="page"
        :total-rows="getTotalLangue"
        :per-page="perPage"
        aria-controls="my-table"
        pills
        last-number
        first-number
        align="center"
        size="sm"
        @change="pagination"
        class=""
      ></b-pagination>
    </div>
    <CommentMarche></CommentMarche>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { required, maxLength } from "@vuelidate/validators";
import deleteModalVue from "../components/ui/deleteModal.vue";
import storeModalVue from "../components/Ajoutmodel/languemodale.vue";
import CommentMarche from "../components/commentMarche.vue";

export default {
  components: {
    deleteModalVue,
    storeModalVue,
    CommentMarche,
  },
  setup() {
    return { v$: useVuelidate({ $lazy: true, $autoDirty: true }) };
  },
  data() {
    return {
      langueToConfig: null,
      langueToDelete: null,
      page: 1,
      perPage: 10,
      oldrefrence: null,
      errorD: false,
      errorU: false,
    };
  },
  validations() {
    return {
      langueToConfig: {
        lib: { required, maxLength: maxLength(50) },
        iso_code: { required },
      },
    };
  },
  methods: {
    ...mapActions([
      "edit_langue",
      "allActiveLangues",
      "store_langue",
      "delete_langue",
      "allLangue",
    ]),

    hideModal(ref) {
      this.$refs[ref].hide();
    },
    handleUpdate(LANG) {
      this.langueToConfig = { ...LANG };
      this.$refs["ModelConfiglangue"].show();
    },
    handleDelete(LANG) {
      this.langueToDelete = { ...LANG };
      this.$root.$emit("bv::show::modal", "ModelDelete");
    },
    async langueModalNew() {
      const isFormCorrect = await this.v$.langueToConfig.$error;
      if (isFormCorrect) return;

      await this.edit_langue({
        lib: this.langueToConfig.lib,
        iso_code: this.langueToConfig.iso_code,
        id: this.langueToConfig.id,
      })
        .then(() => {
          this.hideModal("ModelConfiglangue");
          this.errorU = null;
        })
        .catch((err) => {
          this.errorU = err;
        });
    },

    handleStore() {
      this.allLangue({
        page: 1,
        per_page: 10,
      }).then(() => {
        this.$root.$emit("bv::show::modal", "langueModal");
      });
    },

    async langueDelete() {
      await this.delete_langue({
        id: this.langueToDelete.id,
      })
        .then(() => {
          this.hideModal("ModelDeletelangue");
          this.errorD = null;
        })
        .catch((err) => {
          this.errorD = err;
        });
    },

    pagination(paginate) {
      this.page = paginate;
      this.allActiveLangues({
        page: this.page,
        per_page: this.perPage,
      });
    },

    setup(refresh = false) {
      if (refresh) this.page = 1;
      this.allActiveLangues({
        page: this.page,
        per_page: this.perPage,
      });
    },
  },

  mounted() {
    this.setup(true);
  },

  computed: {
    ...mapGetters([
      "getActiveLangue",
      "getLoadinglangue",
      "getTabs",
      "getTotalLangue",
    ]),
  },
};
</script>

<style lang="scss" scoped>
.content {
  & p {
    font-size: 12px;
    font-weight: 500;
    padding: 3px 0;
    margin: 0;
  }
}

.w-100 {
  background-color: #f8f8f8;
  padding: 4px;
  overflow-x: auto;
}

body {
  background: #fafdff;
}

.actionModel {
  position: relative;
  padding: 15px 0 0;

  .messageError {
    position: absolute;
    top: 0;
    color: #e4261b;
    margin: 0;
    font-size: 12px;
  }
  & .block-spinner {
    display: flex;
    justify-content: center;
  }

  & .spinner-border {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.detailClient {
  background-color: #fff;

  box-shadow: 1px 1px 24px #00000019;

  .clientTop {
    padding: 16px 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-bottom: 1px solid #707070;

    & .prodimg {
      max-width: 185px;
      max-height: 231px;
    }

    .star {
      color: #ffc400;
      position: absolute;
      top: 12px;
      right: 8px;
      font-size: 30px;
    }
  }

  .clientMid {
    padding: 16px 8px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    border-bottom: 1px solid #707070;

    & > div {
      width: 50%;
    }
  }

  .clientBot {
    padding: 16px 8px;
  }

  & h4 {
    color: #28367a;
    font-size: 18px;
    font-weight: 600;
    margin: 0;
  }

  & p {
    color: #515151;
    font-size: 16px;
    font-weight: 500;
  }
}
.filtre {
  display: flex;
  align-items: center;

  .table-count,
  .productTrie {
    margin-right: 15px;
  }

  .productTrie {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    overflow: hidden;
    padding: 4px 8px;
    border-radius: 32px;
    background-color: #34c38f;
    color: #fff;

    .icons {
      cursor: pointer;
      width: 18px;
      height: 18px;
      margin-left: 4px;
    }
  }
}
</style>
